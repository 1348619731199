// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-deportes-js": () => import("./../../../src/pages/deportes.js" /* webpackChunkName: "component---src-pages-deportes-js" */),
  "component---src-pages-futbol-js": () => import("./../../../src/pages/futbol.js" /* webpackChunkName: "component---src-pages-futbol-js" */),
  "component---src-pages-futbolmx-js": () => import("./../../../src/pages/futbolmx.js" /* webpackChunkName: "component---src-pages-futbolmx-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nfl-js": () => import("./../../../src/pages/nfl.js" /* webpackChunkName: "component---src-pages-nfl-js" */),
  "component---src-templates-notedetails-js": () => import("./../../../src/templates/notedetails.js" /* webpackChunkName: "component---src-templates-notedetails-js" */)
}

