import React from "react"
import { Link } from "gatsby"
import "../css/style.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Social() {
  const data = useStaticQuery(graphql`
    query SOCIAL {
      fb: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
      tw: file(relativePath: { eq: "twitter.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
      in: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
      yt: file(relativePath: { eq: "youtube.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
    }
  `)

  return (
    <section className="social-section">
      <div className="social-div">
        <Link to="https://www.facebook.com/MuchoFuchoMX" target="_blank">
          <Img
            className="social-icon s1"
            alt=""
            fluid={data.fb.childImageSharp.fluid}
          />
        </Link>
        <div className="social-bann s1">
          <Link
            className="social-link"
            to="https://www.facebook.com/MuchoFuchoMX"
            target="_blank"
          >
            MuchoFuchoMX
          </Link>
        </div>
      </div>
      <div className="social-div">
        <Link to="https://www.instagram.com/muchofuchomx/" target="_blank">
          <Img
            className="social-icon s2"
            alt=""
            fluid={data.in.childImageSharp.fluid}
          />
        </Link>
        <div className="social-bann s2">
          <Link
            className="social-link"
            to="https://www.instagram.com/muchofuchomx/"
            target="_blank"
          >
            @muchofuchomx
          </Link>
        </div>
      </div>
      <div className="social-div">
        <Link to="https://twitter.com/muchofuchomx" target="_blank">
          <Img
            className="social-icon s3"
            alt=""
            fluid={data.tw.childImageSharp.fluid}
          />
        </Link>
        <div className="social-bann s3">
          <Link
            className="social-link"
            to="https://twitter.com/muchofuchomx"
            target="_blank"
          >
            muchofuchomx
          </Link>
        </div>
      </div>
      <div className="social-div">
        <Link
          to="https://www.youtube.com/channel/UCQmzcmtxRwcHG7isgM_aOpA"
          target="_blank"
        >
          <Img
            className="social-icon s4"
            alt=""
            fluid={data.yt.childImageSharp.fluid}
          />
        </Link>
        <div className="social-bann s4">
          <Link
            className="social-link"
            to="https://www.youtube.com/channel/UCQmzcmtxRwcHG7isgM_aOpA"
            target="_blank"
          >
            Mucho Fucho MX
          </Link>
        </div>
      </div>
    </section>
  )
}
