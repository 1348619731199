import React from "react"
import "../css/style.css"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import logo from "../images/logo.png"

export default function Burgermenu() {
  const data = useStaticQuery(graphql`
    query Burgermenu {
      instagram: file(relativePath: { eq: "in.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
      facebook: file(relativePath: { eq: "fb.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
      youtube: file(relativePath: { eq: "yt.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
      twitter: file(relativePath: { eq: "tw.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
    }
  `)

  return (
    <section id="burgernavbar-style" className="section1">
      <input id="main-menu-checkbox" type="checkbox" />

      <header className="header-area">
        <Link to="/">
          <img className="navbar-logo-burger" alt="" src={logo} />
        </Link>
        <div className="grid-container-burgernav">
          <div className="burger-grid">
            <label
              for="main-menu-checkbox"
              className="zoom menu-toggle label"
              id="main-menu-toggle"
            >
              <span className="sr-only">Open</span>
              <span className="fa fa-bars"></span>
            </label>

            <div className="d-inline-flex">
              <nav
                id="main-menu"
                role="navigation"
                className="main-menu"
                aria-label="Main menu"
              >
                <div className="burgernav-box">
                  <Link to="/futbolmx" className="burgernav-title">
                    FUTBOL MEXICANO
                  </Link>
                  <div /*>  ////  Submenus  ////
                  <Link
                    to="/futbolmx/ligamx"
                  >
                    LIGA MX
                  </Link>
                  <Link to="/futbolmx/ligamxfemenil" className="messages">
                    LIGA MX FEMENIL
                  </Link>
                  <Link to="/futbolmx/ligaexpansion" className="signout">
                    LIGA EXPANSIÓN
                  </Link>
                  <Link to="/futbolmx/ligabalompie" className="signout">
                    LIGA BALOMPIÉ
                  </Link>
                  <Link to="/futbolmx/seleccion" className="signout">
                    SELECCIÓN
                  </Link>
                  <Link to="/futbolmx/seleccionfemenil" className="signout">
                    FEMENIL
                  </Link> */
                  ></div>
                  <Link to="/futbol" className="burgernav-title">
                    FUTBOL INTERNACIONAL
                  </Link>
                  <div /*>  //// submenus ////
                  <Link to="#" class="documents">
                    CHAMPIONS
                  </Link>
                  <Link to="#" class="messages">
                    PREMIER
                  </Link>
                  <Link to="#" class="signout">
                    LA LIGA
                  </Link>
                  <Link to="#" class="signout">
                    SERIES A
                  </Link>
                  <Link to="#" class="signout">
                    BUNDESLIGA
                  </Link>
                  <Link to="#" class="signout">
                    EUROPEA L
                  </Link>
                  <Link to="/futbol/selecciones" class="signout">
                    SELECCIONES
                  </Link>*/
                  ></div>
                  <Link to="/nfl" className="burgernav-title">
                    NFL
                  </Link>
                  <Link to="/deportes" className="burgernav-title">
                    MAS DEPORTES...
                  </Link>
                  <div /*>  //// submenus  ////
                  <Link to="/f1" class="documents">
                    F1
                  </Link>
                  <Link to="/luchalibre" class="messages">
                    LUCHA LIBRE
                  </Link>
                  <Link to="/tenis" class="signout">
                    TENIS
                  </Link>
                  <Link to="/baseball" class="signout">
                    BASEBALL
                  </Link>*/
                  ></div>

                  <p className="footer-follow">¡Síguenos!</p>
                  <div className="grid-container-burgernav-social">
                    <Link
                      className="burgernav-logo-social"
                      to="https://www.facebook.com/MuchoFuchoMX"
                      target="_blank"
                    >
                      <Img alt="" fluid={data.facebook.childImageSharp.fluid} />
                    </Link>
                    <Link
                      className="burgernav-logo-social"
                      to="https://www.instagram.com/muchofuchomx/"
                      target="_blank"
                    >
                      <Img
                        alt=""
                        fluid={data.instagram.childImageSharp.fluid}
                      />
                    </Link>
                    <Link
                      className="burgernav-logo-social"
                      to="https://twitter.com/muchofuchomx"
                      target="_blank"
                    >
                      <Img alt="" fluid={data.twitter.childImageSharp.fluid} />
                    </Link>
                    <Link
                      className="burgernav-logo-social"
                      to="https://www.youtube.com/channel/UCQmzcmtxRwcHG7isgM_aOpA"
                      target="_blank"
                    >
                      <Img alt="" fluid={data.youtube.childImageSharp.fluid} />
                    </Link>
                  </div>
                </div>
              </nav>

              <label
                for="main-menu-checkbox"
                className="backdrop"
                tabindex="-1"
                aria-hidden="true"
                hidden
              ></label>
            </div>
          </div>
        </div>
      </header>
    </section>
  )
}
