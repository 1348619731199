import React from "react"
import "../css/style.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

export default function Navbar() {
  const data = useStaticQuery(graphql`
    query NAVBAR {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
    }
  `)

  return (
    <section className="navbar-section">
      <div className="grid-container-navbar">
        <div className="navbar-logo-sec">
          <Link to="/">
            <Img
              className="navbar-logo"
              alt=""
              fluid={data.logo.childImageSharp.fluid}
            />
          </Link>
        </div>
        <div>
          <ul className="navbar-nav ">
            <li className="identy-hover">
              <Link to="/futbolmx" className="identy">
                FUTBOL MEXICANO
              </Link>
            </li>
            <ul /*> ////  submenu futbol mx  ////
              <li className="navbar-li">
                <Link to="/futbolmx/ligamx">LIGA MX</Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbolmx/ligamxfemenil">LIGA MX FEMENIL</Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbolmx/ligaexpansion">LIGA EXPANSIÓN</Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbolmx/ligabalompie">LIGA BALOMPIÉ</Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbolmx/seleccion">SELECCIÓN</Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbolmx/seleccionfemenil">FEMENIL</Link>
              </li>
            </ul*/
            ></ul>
          </ul>
        </div>
        <div>
          <ul className="navbar-nav">
            <li className="identy-hover">
              <Link to="/futbol" className="identy">
                FUTBOL INTERNACIONAL
              </Link>
            </li>
            <ul /*> ////  submenu futbol  ////
              <li className="navbar-li">
                <Link to="/futbol/championsleague">
                  CHAMPIONS LEAGUE
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbol/europealeague">
                  EUROPEA LEAGUE
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbol/mundial">
                  MUNDIAL
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbol/eurocopa">
                  EUROCOPA
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbol/nationsleague">
                  NATIONS LEAGUE
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbol/premierleague">
                  PREMIER LEAGUE
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbol/laliga">
                  LA LIGA
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="futbol/seriea">
                  SERIE A
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbol/bundesliga">
                  BUNDESLIGA
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/futbol/selecciones">
                  SELECCIONES
                </Link>
              </li>
            </ul */
            ></ul>
          </ul>
        </div>
        <div>
          <ul className="navbar-nav">
            <li className="identy-hover">
              <Link to="/nfl" className="identy">
                NFL
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <ul className="navbar-nav">
            <li className="identy-hover">
              <Link to="/deportes" className="identy">
                MAS DEPORTES...
              </Link>
            </li>
            <ul /*>  ////  submenu otros  ////
              <li className="navbar-li">
                <Link to="/f1">
                  F1
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/tenis">
                  TENIS
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/luchalibre">
                  LUCHA LIBRE
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/baseball">
                  BASEBALL
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/olimpicos">
                  OLÍMPICOS
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/apuestas">
                  APUESTAS
                </Link>
              </li>
              <li className="navbar-li">
                <Link to="/esports">
                  ESPORTS
                </Link>
              </li>
            </ul */
            ></ul>
          </ul>
        </div>
        <div></div>
      </div>
    </section>
  )
}
