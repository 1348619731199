import PropTypes from "prop-types"
import React from "react"
import Burgermenu from "./burgermenu"
import Navbar from "./navbar"

const Header = () => (
  <header>
    <meta name="robots" content="noindex,nofollow" />
    <div className="navbar-display">
      <Navbar />
    </div>
    <div className="burgermenu-display">
      <Burgermenu />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
