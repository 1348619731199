import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
import "../css/style.css"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query Footer {
      instagram: file(relativePath: { eq: "in.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
      facebook: file(relativePath: { eq: "fb.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
      youtube: file(relativePath: { eq: "yt.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
      twitter: file(relativePath: { eq: "tw.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
    }
  `)

  return (
    <footer className="footer-section">
      <div className="footer-border">
        <p className="footer-subscribe">SUSCRÍBETE</p>
        <div className="footer-span"></div>
        <p className="footer-text">
          ¡Recibe lo mejor de <strong>Mucho Fucho</strong> a dos de tres caídas,
          sin límite de tiempo!
        </p>
        <div className="footer-form">
          <form
            method="post"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
            name="case"
            action=""
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="newsletter" />
            <div>
              <input
                className="footer-textbox"
                type="email"
                name="Correo Electronico:"
                placeholder="INGRESA TU E-MAIL"
              />
              <input className="footer-input" type="submit" value=" " />
            </div>
          </form>
        </div>
        <p className="footer-follow">¡Síguenos!</p>
        <div className="grid-container-footer">
          <Link
            className="footer-logo"
            to="https://www.facebook.com/MuchoFuchoMX"
            target="_blank"
          >
            <Img alt="" fluid={data.facebook.childImageSharp.fluid} />
          </Link>
          <Link
            className="footer-logo"
            to="https://www.instagram.com/muchofuchomx/"
            target="_blank"
          >
            <Img alt="" fluid={data.instagram.childImageSharp.fluid} />
          </Link>
          <Link
            className="footer-logo"
            to="https://twitter.com/muchofuchomx"
            target="_blank"
          >
            <Img alt="" fluid={data.twitter.childImageSharp.fluid} />
          </Link>
          <Link
            className="footer-logo"
            to="https://www.youtube.com/channel/UCQmzcmtxRwcHG7isgM_aOpA"
            target="_blank"
          >
            <Img alt="" fluid={data.youtube.childImageSharp.fluid} />
          </Link>
        </div>
      </div>
    </footer>
  )
}
